@import "../../../node_modules/react-calendar/dist/Calendar.css";


.react-calendar {
    width: 100%;
    border-radius: 5px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a09636;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
}

.react-calendar__tile--now {
    background: #ca0c0cc4 !important;
    color: white!important;
    border-radius: 3px;
}

.react-calendar__tile--active {
    background: #006edc!important;
    color: white!important;
    border-radius: 3px;
}